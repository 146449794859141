import { useMediaQuery } from 'react-responsive';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import { UserRole } from '../../../models/user/user';
import { getEnumText } from '../../../shared/utils';
import { useAuth } from '../../context/auth_provider';
import { useEffect } from 'react';

interface Props {
    showSidebar: () => void,
    logoutHandler: () => void,
}

function TopMenu({ showSidebar, logoutHandler }: Props) {
    const {user, isAuthenticated} = useAuth();
    const showFullSize = useMediaQuery({ query: "(min-width:576px)" })
    const isLoading = false;

   useEffect(() => {
    if (!localStorage.getItem('token')){
        window.location.href = "/login";
    }
    }, []);

    return (
        <Menu fixed='top' inverted borderless size={showFullSize ? 'large' : 'tiny'} className="nav-menu">
            <Icon link name='sidebar' size='large' onClick={showSidebar} className={showFullSize ? 'burgerMenu' : 'burgerMenuSmall'} />
            <Menu.Item header>
                <h2><Image src='/assets/logo_white.png' size='tiny' as={NavLink} to='/' />
                &nbsp;&nbsp;&nbsp;&nbsp;  {user?.accountName}</h2>
            </Menu.Item>
            {showFullSize && !isLoading && isAuthenticated && (
                <>
                    {/*
                    {entity.id > 0 &&
                        <Menu.Item>
                            <Dropdown pointing='top left' text={entity.name} className="nav-sel">
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to='/switch' text='Switch' icon='building outline' />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    }
                */}
                    <Menu.Menu position='right'>
                        <Menu.Item position="right">
                            <Image src='/assets/user.png' avatar spaced='right' />
                            {
                            <Dropdown pointing='top left' text={getEnumText(UserRole, user?.role ?? 0) + " : " + user?.displayName} className="nav-sel">
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to={`/profile`} text='Profile' icon='user' />
                                    <Dropdown.Item onClick={logoutHandler} text='Log Out' icon='power' />
                                </Dropdown.Menu>
                            </Dropdown>
                            }
                        </Menu.Item>
                    </Menu.Menu>
                </>
            )}
        </Menu>
    )
}

export default TopMenu