import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Dropdown, Form, Grid, Icon, Table } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";
import { useAuth } from "../../app/context/auth_provider";
import { Result } from "../../models/common/paged_result";
import { CertificateData, CourseAllStudentProgressReport } from "../../models/report/report";
import { UserListOutDTO } from "../../models/user/user";
import { api } from "../../shared/axios_wrapper";
import AccountLocationSelectionPanel from "../common/account_location_sel";
import DownloadCSV from "../../app/components/download_report";
import CertificateModal from "../lesson/components/certificate_modal";
import { formatDate } from "../../shared/utils";

export function StudentCertificateProgress() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  //const [reload, setReload] = useState(0);
  const [locationId, setLocationId] = useState(0);
  const [locationStudentId, setLocationStudentId] = useState(0);
  const [studentValues, setStudentValues] = useState<any[]>([]);
  const [studentReport, setStudentReport] = useState<CourseAllStudentProgressReport[]>();

  const [selectedCertificateData, setSelectedCertificateData] = useState<CertificateData>();
  const [showCert, setShowCert] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const downloadParams = {
    accountId: user?.accountId,
    locationId: locationId,
    studentId: locationStudentId,
    sortIndex: "studentName",
    sortOrder: 'ascending',
    pageIndex: 0,
    pageSize: 10000,
    download: true
  };

  const getDownloadUrl = () => {return  `student-progress-report`;}

  useEffect(() => {
    if (locationId > 0) {
      setLoading(true);
      const url = `account/${user?.accountId}/location/${locationId}/user/drop-down-list`;
      api.get<Result<UserListOutDTO[]>>(url)
        .then((r) => {
          if (r?.data?.result && Array.isArray(r.data.result)){
              const transformedList = (r?.data?.result?.map(item => ({
              key: item.userId,
              value: item.userId,
              text: item.userName
            })));
            setStudentValues(transformedList);
          }
        })
        .catch((e) => {toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
  }, [locationId, user?.accountId]);

  const showReport = () => {
    if ((user?.accountId ?? 0) > 0 &&
      locationId > 0 &&
      locationStudentId > 0)  {
        setSubmitting(true);
      const url = `report/student-progress-report`;
      var params = {
        accountId: user?.accountId ?? 0,
        locationId: locationId,
        studentId: locationStudentId
      };
      //console.log(params);
      api.getWithInput<Result<CourseAllStudentProgressReport[]>>(url, params)
        .then((r) => {
          if (r.data?.result){
            setStudentReport(r.data.result);
            //console.log(r.data.result);
          }
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setSubmitting(false));
    }
  }

  const handleLocationSelect = useCallback((locationId: number) => {
    setLocationId(locationId);
  }, []);

  const showViewCertificate = (c: CourseAllStudentProgressReport) => {
    if (!c.certifiedOn) return false;
    return true;
  }

  const handleViewCertificate = (c: CourseAllStudentProgressReport) => {
    if (!c.certifiedOn) return false;

    setSelectedCertificateData({
      accountName: c?.accountName ?? "",
      locationName: c?.locationName ?? "",
      courseName: c?.courseName ?? "",
      courseDescription: c?.courseShortDescription ?? "",
      courseIcon: c?.courseIcon ?? "",
      studentName: c?.studentName ?? "",
      certifiedOn: c.certifiedOn
    });

    setShowCert(true);
  }

  const getDownloadFileName = () => {
    if (locationStudentId > 0)
      return studentReport![0].studentName + "_all_course_certificate.csv";
    else
     return "student_course_certificate.csv";
  }

  const getStudentCourseStatus = (c: CourseAllStudentProgressReport) => {
    if (!c.lastAccessedDate) return "Yet to Start";
    if (c.certifiedOn) return "Completed";
    return "In Progress";
  }

  const FilterStudentProgress = () => {
    return (
      <Form className="ui form" onSubmit={showReport}>
        <Grid stackable container>
          <Grid.Row>
            <Grid.Column width={6} verticalAlign='middle'>
              <AccountLocationSelectionPanel locationId={locationId} onLocationSelect={handleLocationSelect} />
            </Grid.Column>
            <Grid.Column width={6}>
              <label className="txt-content" style={{ lineHeight: "2" }}>Student:</label> <br />
              <Dropdown
                placeholder=''
                fluid
                search
                selection
                size="huge"
                className="txt-content"
                options={studentValues}
                value={locationStudentId}
                onChange={(e, data) => { setLocationStudentId(data.value as number); }}
              />
            </Grid.Column>
            <Grid.Column textAlign='right' width={4} verticalAlign='middle'>
              <br />
              <Button type="submit" primary
                disabled={submitting}
                size='large'>
                Show Report
                {submitting && <label> <Icon name='spinner' /></label>}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  const StudentReport = () => {
    return (
      <>
        <br/>
        {(studentReport?.length ?? 0) > 0 && <DownloadCSV
                                    url= {getDownloadUrl()}
                                    params = {downloadParams}
                                    fileName = {getDownloadFileName()}
                                    />}
      <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Student Name</Table.HeaderCell>
          <Table.HeaderCell>Course Name</Table.HeaderCell>
          <Table.HeaderCell>Course Status</Table.HeaderCell>
          <Table.HeaderCell>Last Accessed On</Table.HeaderCell>
          <Table.HeaderCell>Date Certified</Table.HeaderCell>
          <Table.HeaderCell>Avg. Quiz Score</Table.HeaderCell>
          <Table.HeaderCell>Certificate</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {studentReport?.map((s, index) => (
          <Table.Row key={"student_" + s.studentId + 'course_' + s.courseId}>
            <Table.Cell>{s.studentName}</Table.Cell>
            <Table.Cell>{s.courseName}</Table.Cell>
            <Table.Cell>{getStudentCourseStatus(s)}</Table.Cell>
            <Table.Cell>{formatDate(s.lastAccessedDate)}</Table.Cell>
            <Table.Cell>{formatDate(s.certifiedOn)}</Table.Cell>
            <Table.Cell textAlign="center">{s.avgQuizMarks}</Table.Cell>
            <Table.Cell>
              <Button  basic color='blue'  icon='star outline' content='View Certificate'
                  disabled={!showViewCertificate(s)}
                  onClick={() => handleViewCertificate(s)}/>
          </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
        <Table.HeaderCell colSpan={7} textAlign="right">
        {(studentReport?.length ?? 0) > 0 && <DownloadCSV
                                  url= {getDownloadUrl()}
                                  params = {downloadParams}
                                  fileName = {getDownloadFileName()}
                                  />}
        </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
    </>
    );
  }
  return (
    <>
      <DocumentTitle title="Student Progress Report" />
      <Button basic icon="arrow left" size="big" content="Back to Reports" onClick={() => {navigate('/reports/progress')}}/>
      <h3><Icon name='pie chart' /> Student Progress Report</h3>

      <FilterStudentProgress />
      {!loading && <StudentReport />}

      {showCert && <CertificateModal
                        certificateData={selectedCertificateData}
                        showModal={showCert}
                        setShowModal={setShowCert}/>}
    </>
  );
}