import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import AccountCourseListPage from '../../features/account/account_course_list';
import AccountEditPage from '../../features/account/account_edit';
import AccountListPage from '../../features/account/account_list';
import AccountViewPage from '../../features/account/account_view';
import LocationCourseListPage from '../../features/account/location_course_list';
import LocationEditPage from '../../features/account/location_edit';
import LocationListPage from '../../features/account/location_list';
import LocationViewPage from '../../features/account/location_view';
import AccountSwitchPage from '../../features/admin/account_switch';
import AdminPage from '../../features/admin/admin';
import CourseEditPage from '../../features/course/course_edit';
import CourseListPage from '../../features/course/course_list';
import CourseViewPage from '../../features/course/course_view';
import ModuleEditPage from '../../features/course/module_edit';
import ModuleListPage from '../../features/course/module_list';
import ModuleViewPage from '../../features/course/module_view';
import LessonCourseList from '../../features/lesson/lesson_course_list';
import LessonCourseView from '../../features/lesson/lesson_course_view';
import LessonModuleView from '../../features/lesson/lesson_module_view';
import LoginPage from '../../features/login/login';
import LogoutPage from '../../features/logout/logout';
import CertificateReportPage from '../../features/report/certificate_report';
import { CourseCertificateProgress } from '../../features/report/course_certifications';
import { StudentCertificateProgress } from '../../features/report/student_certifications';
import ResetPasswordPage from '../../features/users/reset_pwd';
import UserEditPage from '../../features/users/user_edit';
import UserListPage from '../../features/users/user_list';
import UserViewPage from '../../features/users/user_view';
import { UserRole } from '../../models/user/user';
import { AuthProvider } from '../context/auth_provider';
import UnauthorizedPage from '../unauthorized';
import NavBarLayout from './nav_bar_layout';
import NotFound from './not_found';
import ProtectedRoute from './protected_route';
import { TermsPage } from '../../features/common/terms';
import { SimpleTermsPage } from '../../features/common/terms_simple';
import HelpPage from '../../features/common/help';
import { UserBulkActionPage } from '../../features/users/user_bulk_action';
import SelfRegisterPage from '../../features/login/self_register';
import LessonModuleTasks from '../../features/lesson/lesson_module_tasks';
import LessonModuleAssessment from '../../features/lesson/lesson_module_task_assessment';
import LessonCourseTimelineView from '../../features/lesson/lesson_course_timeline_view';
import DataFileExplorerPage from '../../features/account/data_file_explorer';

function App() {
  const toastConfig: ToastContainerProps = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  const router = createBrowserRouter(createRoutesFromElements(
    <>
      <Route path='/login' element={<LoginPage />} />
      <Route path='/self-register' element={<SelfRegisterPage />} />
      <Route path='/user/reset-pwd/:initialLogin?/:initialLastName?/:pin?' element={<ResetPasswordPage />} />
      <Route element={<NavBarLayout />}>
        <Route element={<ProtectedRoute allowedRole={UserRole.SuperAdmin} />}>
          <Route path='/admin' element={<AdminPage />} />
          <Route path='/admin/courses' element={<CourseListPage />} />
          <Route path='/admin/courses/:courseId' element={<CourseViewPage/>} />
          <Route path='/admin/courses/edit/:courseId' element={<CourseEditPage />} />
          <Route path='/admin/modules' element={<ModuleListPage />} />
          <Route path='/admin/modules/:moduleCode' element={<ModuleViewPage />} />
          <Route path='/admin/modules/edit/:moduleCode' element={<ModuleEditPage />} />
          <Route path='/admin/accounts/edit/:accountId' element={<AccountEditPage />} />
          <Route path='/admin/account/user-bulk-action' element={<UserBulkActionPage/>} />
          <Route path='/admin/locations/edit/:locationId' element={<LocationEditPage />} />
          <Route path='/switch' element={<AccountSwitchPage />} />
        </Route>
        <Route element={<ProtectedRoute allowedRole={UserRole.AccountAdmin} />}>
          <Route path='/accounts' element={<AccountListPage />} />
          <Route path='/accounts/:accountId' element={<AccountViewPage />} />
          <Route path='/account/account-courses' element={<AccountCourseListPage />} />
        </Route>
        <Route element={<ProtectedRoute allowedRole={UserRole.LocationAdmin} />}>
          <Route path='/locations' element={<LocationListPage />} />
          <Route path='/locations/:locationId' element={<LocationViewPage />} />
          <Route path='/location/location-courses' element={<LocationCourseListPage />} />
          <Route path='/location/users' element={<UserListPage />} />
          <Route path='/location/users/:userId' element={<UserViewPage/>} />
          <Route path='/location/users/edit/:userId' element={<UserEditPage/>} />
          <Route path='/location/data-files' element={<DataFileExplorerPage />} />
          <Route path='/reports/progress' element={<CertificateReportPage/>} />
          <Route path='/location/reports/student-progress' element={<StudentCertificateProgress/>} />
          <Route path='/location/reports/course-progress' element={<CourseCertificateProgress/>} />
        </Route>
        <Route element={<ProtectedRoute allowedRole={UserRole.Student} />} >
          <Route path='/' element={<LessonCourseList />} />
          <Route path='/home' element={<LessonCourseList />} />
          <Route path='/lessons/courses' element={<LessonCourseList/>} />
          <Route path='/lessons/courses/:courseId' element={<LessonCourseView/>} />
          <Route path='/lessons/courses/:courseId/timeline' element={<LessonCourseTimelineView/>} />
          <Route path='/lessons/courses/:courseId/modules/:moduleCode' element={<LessonModuleView />} />
          <Route path='/lessons/courses/:courseId/modules/tasks/:moduleCode' element={<LessonModuleTasks />} />
          <Route path='/lessons/courses/:courseId/modules/assessment' element={<LessonModuleAssessment/>} />
          <Route path='/profile' element={<UserViewPage />} />
          <Route path='/logout' element={<LogoutPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/terms-of-use" element={<TermsPage />} />
          <Route path="/terms-of-use-plain" element={<SimpleTermsPage />} />
        </Route>
      </Route>
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/terms-plain" element={<SimpleTermsPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFound />} />
    </>
  ))

  return (
    <AuthProvider>
      <>
        <ToastContainer {...toastConfig} />
        <RouterProvider router={router} />
      </>
    </AuthProvider>
  );
}

export default App;
