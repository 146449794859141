import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Dropdown, Form, Grid, Icon, Segment, Select, Table } from "semantic-ui-react";
import { DocumentTitle } from "../../app/components/document_title";
import { useAuth } from "../../app/context/auth_provider";
import { Result } from "../../models/common/paged_result";
import { CourseListOutputDto } from "../../models/course/course";
import { CertificateData, CourseAllModuleData, CourseAllStudentProgressReport, UserCourseStatus } from "../../models/report/report";
import { api } from "../../shared/axios_wrapper";
import AccountLocationSelectionPanel from "../common/account_location_sel";
import DownloadCSV from "../../app/components/download_report";
import CertificateModal from "../lesson/components/certificate_modal";
import { formatDate, getEnumText } from "../../shared/utils";
import { UserListOutDTO, UserRole, roleListJson } from "../../models/user/user";
import { CourseAllModuleProgress } from "./student_course_all_module_progress";

export function CourseCertificateProgress() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  //const [reload, setReload] = useState(0);
  const [locationId, setLocationId] = useState(0);
  const [courseValues, setCourseValues] = useState<any[]>([]);
  const [locationCourseId, setLocationCourseId] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState<number>(UserRole.Student);
  const [studentValues, setStudentValues] = useState<any[]>([]);
  const [locationStudentId, setLocationStudentId] = useState(0);
  const [courseStatus, setCourseStatus] = useState<number>(-1);
  const [courseReport, setCourseReport] = useState<CourseAllStudentProgressReport[]>();

  const [selectedCertificateData, setSelectedCertificateData] = useState<CertificateData>();
  const [showCert, setShowCert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showModuleRpt, setShowModuleRpt] = useState(false);
  const [selectedCourseAllModuleData, setSelectedCourseAllModuleData] = useState<CourseAllModuleData>();

  const courseStatusOptions = [
    { key: 'all', text: 'All', value: -1 },
    { key: 'completed', text: 'Certified', value: 2},
    { key: 'inProgress', text: 'In Progress', value: 1},
    { key: 'yetToStart', text: 'Yet to Start', value: 0 },
  ];

  const downloadParams = {
    accountId: user?.accountId,
    locationId: locationId,
    courseId: locationCourseId,
    courseStatus: courseStatus,
    roleId: selectedRoleId,
    studentId: locationStudentId,
    sortIndex: "studentName",
    sortOrder: 'ascending',
    pageIndex: 0,
    pageSize: 10000,
    download: true
  };

  const getDownloadUrl = () => {return  `report/course-all-student-progress-report`;}

  useEffect(() => {
    if (locationId > 0) {
      setLoading(true);
        const url = `account/${user?.accountId}/location/${locationId}/location-course-list`;
        api.get<Result<CourseListOutputDto[]>>(url)
          .then((r) => {
            const transformedList = (r.data.result.map(item => ({
              key: item.id,
              value: item.id,
              text: item.courseName
            })));

            const transformedListAll = [
              { key: 'All', value: 0, text: 'All' },
              ...transformedList,
          ];

          setCourseValues(transformedListAll);
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));
    }
    else{
      const transformedListAll = [
        { key: 'All', value: 0, text: 'All' }
      ];

      setCourseValues(transformedListAll);
    }
  }, [locationId, user?.accountId]);

  useEffect(() => {
      setLoading(true);
      const url = `account/${user?.accountId}/location/${locationId}/user/drop-down-list`;
      api.get<Result<UserListOutDTO[]>>(url)
        .then((r) => {
          if (r?.data?.result && Array.isArray(r.data.result)){
              const transformedList = (r?.data?.result?.map(item => ({
                key: item.userId,
                value: item.userId,
                text: item.userName,
                role: item.roleId
              })));

            const transformedListAll = [
              { key: 'All', value: 0, text: 'All', role: 0 },
              ...transformedList,
            ];

            setStudentValues(transformedListAll);
          }
        })
        .catch((e) => {toast.error("Error", { theme: "colored" }) })
        .finally(() => setLoading(false));

  }, [user?.accountId, locationId]);

  const showReport = () => {
    if ((user?.accountId ?? 0) > 0) {
        setSubmitting(true);
      const url = `report/course-all-student-progress-report`;
      var params = {
        accountId: user?.accountId ?? 0,
        locationId: locationId,
        roleId: selectedRoleId,
        studentId: locationStudentId,
        courseId: locationCourseId,
        courseStatus: courseStatus
      };

      api.getWithInput<Result<CourseAllStudentProgressReport[]>>(url, params)
        .then((r) => {
          if (r.data?.result){
            setCourseReport(r.data.result);
            //console.log(r.data?.result);
          }
        })
        .catch((e) => { toast.error("Error", { theme: "colored" }) })
        .finally(() => setSubmitting(false));
    }
  }

  const handleLocationSelect = useCallback((locationId: number) => {
    setLocationId(locationId);
  }, []);

  const getStudentCourseStatus = (s: CourseAllStudentProgressReport) => {

    //console.log(s);
    if (s.completedFlag === true &&
      (s.moduleCount ?? 0) === (s.completedModuleCount ?? 0))
        return `Completed (${s.completedModuleCount} of ${s.moduleCount} = ${s.courseProgress}%)`;

    if ((s.status === UserCourseStatus.InProgress || s.moduleCount !== s.completedModuleCount) && (s.completedModuleCount ?? 0) > 0)
        return `In Progress (${s.completedModuleCount} of ${s.moduleCount} = ${s.courseProgress}%)`;

    if (s.lastAccessedDate && (s.avgQuizMarks ?? 0) > 0)
      return `In Progress (${s.completedModuleCount} of ${s.moduleCount} = ${s.courseProgress}%)`;

    return "Yet to Start";
  }

  const showViewCertificate = (s: CourseAllStudentProgressReport) => {
    if (!s.completedFlag) return false;
    return true;
  }

  const handleViewCertificate = (s: CourseAllStudentProgressReport) => {
    if (!s.completedFlag) return false;

    setSelectedCertificateData({
      accountName: s?.accountName ?? "",
      locationName: s?.locationName ?? "",
      courseName: s?.courseName ?? "",
      courseDescription: s?.courseShortDescription ?? "",
      courseIcon: s?.courseIcon ?? "",
      studentName: s.studentName,
      certifiedOn: s.certifiedOn ?? s.lastAccessedDate
    });
    setShowCert(true);
  }


  const handleViewModuleRpt = (s: CourseAllStudentProgressReport) => {
    setSelectedCourseAllModuleData({
      locationId: s?.locationId,
      locationName: s?.locationName ?? "",
      courseId: s?.courseId,
      courseName: s?.courseName ?? "",
      userId: s?.studentId,
      userName: s.studentName,
    });
    setShowModuleRpt(true);
  }

  const getDownloadFileName = () => {
    return "user_course_certificate_data.csv";
    /*
    if (locationCourseId > 0)
      return courseReport![0].locationName + "_sel_course_certificate.csv";
    else
     return courseReport![0].locationName + "_all_course_certificate.csv";
    */
  }

  const FilterCourseProgress = () => {
    return (
      <Form className="ui form" onSubmit={showReport}>
        <Grid stackable container>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign='middle'>
              <AccountLocationSelectionPanel locationId={locationId} onLocationSelect={handleLocationSelect} />
            </Grid.Column>
            <Grid.Column width={3} verticalAlign='middle'>
              <label className="txt-content" style={{ lineHeight: "2" }}>Role:</label> <br />
              <Select
                options={roleListJson.filter(x => x.key !== "0" && x.value <= UserRole.LocationAdmin)}
                onChange={(e,data) => setSelectedRoleId(data.value as number)}
                value={selectedRoleId}
                placeholder="Role" style={{ lineHeight: "2", fontSize: "18"}} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column width={16}>
              <label className="txt-content" style={{ lineHeight: "2" }}>First and Last Name (select or type to filter):</label> <br />
              <Dropdown
                placeholder=''
                fluid
                search
                selection
                size="huge"
                className="txt-content"
                options={studentValues.filter(x => (x.role === selectedRoleId || x.role === 0))}
                value={locationStudentId}
                onChange={(e, data) => { setLocationStudentId(data.value as number); }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column width={8}>
              <label className="txt-content" style={{ lineHeight: "2" }}>Course (select or type to filter):</label> <br />
              <Dropdown
                placeholder='Course'
                fluid
                search
                selection
                size="huge"
                className="txt-content"
                options={courseValues}
                value={locationCourseId}
                onChange={(e, data) => { setLocationCourseId(data.value as number); }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <label className="txt-content" style={{ lineHeight: "2" }}>Certification Status:</label> <br />
              <Dropdown
                placeholder="Select Status"
                fluid
                size="huge"
                className="txt-content"
                selection
                options={courseStatusOptions}
                onChange={(e, data) => {setCourseStatus(data.value as number)}}
                value={courseStatus}
              />
            </Grid.Column>
            <Grid.Column textAlign='right' width={4} verticalAlign='middle'>
              <br />
              <Button type="submit" primary
                disabled={submitting}
                size='big' >
                Show Report
                {submitting && <label> <Icon name='spinner' /></label>}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  const CourseReport = () => {
    return (
      <>
        <br/>
        {(courseReport?.length ?? 0) > 0 && <DownloadCSV
                                    url= {getDownloadUrl()}
                                    params = {downloadParams}
                                    fileName = {getDownloadFileName()}
                                    />}
      <div style={{"paddingTop": "4px", "paddingBottom" : "4px"}}>Report Row Count: {courseReport?.length }</div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Location Name</Table.HeaderCell>
            <Table.HeaderCell>Student Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Course Name</Table.HeaderCell>
            <Table.HeaderCell>Quiz Status</Table.HeaderCell>
            <Table.HeaderCell>Last Accessed On</Table.HeaderCell>
            <Table.HeaderCell>Certified?</Table.HeaderCell>
            <Table.HeaderCell>Date Certified</Table.HeaderCell>
            <Table.HeaderCell>Avg. Quiz Score</Table.HeaderCell>
            <Table.HeaderCell>Certificate</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {courseReport?.map((s, index) => (
            <Table.Row key={"l" + s.locationId + "_s_" + s.studentId + '_c_' + s.courseId}>
              <Table.Cell>{s.locationName}</Table.Cell>
              <Table.Cell>{s.studentName}</Table.Cell>
              <Table.Cell>{getEnumText(UserRole, s.roleId)}</Table.Cell>
              <Table.Cell>
                {s.courseName} &nbsp;
                <Icon name='info circle' color="blue" onClick={() => handleViewModuleRpt(s)}
                style={{cursor: "pointer"}}/>
              </Table.Cell>
              <Table.Cell>{getStudentCourseStatus(s)}</Table.Cell>
              <Table.Cell>{formatDate(s.lastAccessedDate, true)}</Table.Cell>
              <Table.Cell textAlign="center">{(s.completedFlag ?? false) ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>{(s.completedFlag ?? false) ? formatDate(s.certifiedOn, true) : ""}</Table.Cell>
              <Table.Cell textAlign="center">{Math.round(s.avgQuizMarks) >= 0 ?
                                              Math.round(s.avgQuizMarks) + "%" :
                                              Math.round(s.avgQuizMarks)}</Table.Cell>
              <Table.Cell>
                <Button  basic color='blue'  icon='star outline' content='View Certificate'
                    disabled={!showViewCertificate(s)}
                    onClick={() => handleViewCertificate(s)}/>
            </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
          <Table.HeaderCell colSpan={10} textAlign="right">
          {(courseReport?.length ?? 0) > 0 && <DownloadCSV
                                    url= {getDownloadUrl()}
                                    params = {downloadParams}
                                    fileName = {getDownloadFileName()}
                                    />}
          </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      </>
    );
  }

  return (
    <>
      <DocumentTitle title="Student Course Progress Report" />
      <h3><Icon name='chart area' size="big"/> Student Course Progress Report</h3>

      <FilterCourseProgress />
      <Segment color='grey'>
        <Icon name='hand point right' size="large"/>&nbsp;&nbsp;
        Note: <br/>
        1. Only the activity of students and/or location admins are reportable. <br/>
        2. Active and unexpired students and/or location admins certificate data is shown. <br/>
        3. Click <Icon name='info circle' color="blue"/> icon next to course name to view module-level details
        </Segment>
       <CourseReport />

      {!loading && showCert &&
      <CertificateModal
          certificateData={selectedCertificateData}
          showModal={showCert}
          setShowModal={setShowCert}/>}

      {!loading && showModuleRpt &&
      <CourseAllModuleProgress
        showModal={showModuleRpt}
        setShowModal={setShowModuleRpt}
      moduleRptInput={selectedCourseAllModuleData}/>}
    </>
  );
}
